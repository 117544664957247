<div class="feedback-container" [class.collapsed]="isCollapsed">
  <form (ngSubmit)="onSubmit(form.value)" #form="ngForm">
    <div>
      <h4 [hidden]="isCollapsed">Feel free to send us your feedback!</h4>

      <div class="feedback-form" [hidden]="isCollapsed">
        <div class="form-field">
          <label for="featureId">Which part of Core would you like to give feedback about?</label>
          <ng-select
            class="form-control feedback-select"
            labelForId="featureId"
            ngModel
            name="featureId"
            required
            #featureId="ngModel"
            placeholder="Select Feature"
          >
            <ng-option *ngFor="let feature of featureList" [value]="feature.id">{{ feature.featureName }}</ng-option>
          </ng-select>
          <p *ngIf="!featureId.valid && featureId.touched" class="error-message">
            Please select a feature from the list
          </p>
        </div>

        <div class="form-field">
          <label for="title">Feedback Title</label>
          <input
            type="text"
            class="form-control feedback-title"
            placeholder="e.g. Need a filter for ..."
            ngModel
            name="title"
            required
            #title="ngModel"
          />
          <p *ngIf="!title.valid && title.touched" class="error-message">Please enter a title for your feedback</p>
        </div>

        <div class="form-field">
          <label for="feedbackMessage">Feedback Content</label>
          <textarea
            class="form-control feedback-content"
            placeholder="Please enter your feedback here ..."
            ngModel
            name="feedbackMessage"
            required
            #feedbackMessage="ngModel"
          ></textarea>
          <p *ngIf="!feedbackMessage.valid && feedbackMessage.touched" class="error-message">
            Please enter your feedback here
          </p>
        </div>

        <div class="form-field">
          <button type="button" class="btn record-button" (click)="recordScreen()">
            <mat-icon>add_a_photo</mat-icon>
            <p>Record Screen</p>
          </button>

          @if (videoUrl) {
            <ng-container>
              <video [src]="videoUrl" class="video-recording" controls autoplay></video>
              <div class="clear-recording-btn-container">
                <button type="button" class="btn clear-recording" (click)="clearVideo()">
                  <p>Clear Recording</p>
                </button>
              </div>
            </ng-container>
          } @else if (!videoUrl) {
            <h5 class="notification-message">Please note: You can record up to 5 minutes of video feedback.</h5>
          }
        </div>
      </div>
    </div>

    <div class="action-button" [hidden]="isCollapsed">
      <button type="submit" class="btn feedback-submit-btn" [disabled]="!form.valid">Submit</button>
      <button type="button" class="btn feedback-cancel-btn" (click)="closeFeedback()">Cancel</button>
    </div>
  </form>
</div>
<div class="loader-animation" *ngIf="isLoading || isVideoLoading">
  <svg-icon name="loader-animation" style="color: #8f9bba" class="input-icon"></svg-icon>
</div>
