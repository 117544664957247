import { Component, inject } from '@angular/core';
import { InvoiceServicePlan } from 'src/app/shared/enums/enums';
import { InvoiceService } from '../../shared/services/invoice.service';
import { FeedbackWindowService } from '../feedback/feedback-window/feedback-window.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  private feedbackWindowService = inject(FeedbackWindowService);
  isFeedbackOpen = this.feedbackWindowService.isFeedbackWindowOpen;

  constructor(private _invoiceService: InvoiceService) {
    this._invoiceService.setServicePlanData([
      {
        servicePlanName: InvoiceServicePlan.OnDemand,
        id: 1,
      },
      {
        servicePlanName: InvoiceServicePlan.Enterprise,
        id: 2,
      },
      {
        servicePlanName: InvoiceServicePlan.City,
        id: 3,
      },
      {
        servicePlanName: InvoiceServicePlan.ExtendedOnDemand,
        id: 4,
      },
      {
        servicePlanName: InvoiceServicePlan.ExtendedEnterprise,
        id: 5,
      },
    ]);
  }
}
