import { Injectable, signal } from '@angular/core';
import { ScreenCaptureService } from '../screen-capture-api.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbackWindowService {
  isFeedbackWindowOpen = signal(false);
  isFeedbackWindowCollapsed = signal(false);
  isVideoLoading = signal(false);

  private capturedStream: MediaStream | null = null;
  private mediaRecorder: MediaRecorder | null = null;
  private recordedFeedback: Blob[] = [];
  private videoUrl: string | null = null;
  private recordingTimeout: number | null = null;

  public get videoBlob(): Blob | undefined {
    if (this.recordedFeedback.length === 0) return undefined;
    return new Blob(this.recordedFeedback);
  }

  constructor(private screenCaptureService: ScreenCaptureService) {}

  openFeedbackWindow() {
    this.isFeedbackWindowOpen.set(true);
  }

  closeFeedbackWindow() {
    this.isFeedbackWindowOpen.set(false);
  }

  collapseFeedbackWindow() {
    this.isFeedbackWindowCollapsed.set(true);
  }

  expandFeedbackWindow() {
    this.isFeedbackWindowCollapsed.set(false);
  }

  getVideoUrl(): string | null {
    return this.videoUrl;
  }

  async recordScreen(): Promise<void> {
    this.capturedStream = await this.screenCaptureService.startCapture();

    if (this.capturedStream) {
      this.collapseFeedbackWindow();

      this.mediaRecorder = new MediaRecorder(this.capturedStream);
      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.recordedFeedback.push(event.data);
        }
      };

      this.mediaRecorder.onstop = () => {
        this.playRecording();
        this.expandFeedbackWindow();
      };

      this.mediaRecorder.start();

      this.recordingTimeout = window.setTimeout(() => {
        this.stopCapture();
      }, 300000); // 300,000 ms = 5 minutes
    } else {
      console.error('Screen capture failed or was canceled.');
    }
  }

  stopCapture(): void {
    if (this.mediaRecorder && this.capturedStream) {
      if (this.recordingTimeout !== null) {
        clearTimeout(this.recordingTimeout);
        this.recordingTimeout = null;
      }
      this.mediaRecorder.stop();
      this.capturedStream.getTracks().forEach((track) => track.stop());
      this.capturedStream = null;
    }
  }

  private playRecording(): void {
    if (!this.videoBlob) return;
    this.isVideoLoading.set(true);
    this.videoUrl = window.URL.createObjectURL(this.videoBlob);
    this.isVideoLoading.set(false);
  }

  clearVideo() {
    this.videoUrl = null;
    this.recordedFeedback = [];
  }
}
