import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { map, Observable } from 'rxjs';
import { APIUrls } from '../enums/apiUrls';
import { SuccessResponse } from '../interfaces/Admin/SuccessResponse';
import { PaginatedApiResponse } from '../interfaces/api-response.model';
import { AddNewFeedbackRequest } from '../interfaces/Feedback/AddNewFeedbackRequest';
import { FeedbackListModel } from '../interfaces/Feedback/feedback-list-api-models';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class FeedbackApiService {
  constructor(private _apiService: ApiService) {}

  createFeedback(feedback: AddNewFeedbackRequest): Observable<SuccessResponse> {
    // create form data
    const formData = new FormData();
    formData.append('title', feedback.title);
    formData.append('feedbackMessage', feedback.feedbackMessage);
    formData.append('userId', feedback.userId.toString());
    formData.append('featureId', feedback.featureId!.toString());

    if (feedback.video) {
      formData.append('video', feedback.video, 'FeedbackVideo.mp4');
    }

    return this._apiService.post<SuccessResponse>(`${APIUrls.Feedback}`, formData);
  }

  getFeedbackList(
    pageNumber: number,
    pageSize: number,
    userId?: number,
    createdDate?: Moment,
  ): Observable<PaginatedApiResponse<FeedbackListModel>> {
    const params: Partial<{
      pageNumber: number;
      pageSize: number;
      userId: number;
      createdDate: string;
    }> = {
      pageNumber,
      pageSize,
    };

    if (userId) params.userId = userId;
    if (createdDate) params.createdDate = createdDate.toISOString().split('T')[0];

    return this._apiService.get<PaginatedApiResponse<FeedbackListModel>>(`${APIUrls.Feedback}`, params);
  }

  deleteFeedback(id: number): Observable<boolean> {
    return this._apiService
      .delete<SuccessResponse>(`${APIUrls.Feedback}/${id}`)
      .pipe(map((response) => response.result));
  }
}
