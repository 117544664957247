<div class="side-menu" [class.menu-collapsed]="menuCollapsed()">
  <div class="side-menu-logo" disabled>
    <div>
      <img src="../../assets/images/HarperJames-Logo.svg" alt="" *ngIf="!menuCollapsed()" />
    </div>

    <div [ngClass]="menuCollapsed() ? 'collapsed-menu-toogle' : 'menu-toggle'">
      <button
        type="button"
        class="btn menu-toggle-btn"
        [class.menu-collapsed]="menuCollapsed()"
        (click)="toggleMenu(!menuCollapsed())"
        matTooltipPosition="right"
        matTooltip="{{ menuCollapsed() ? 'Show more information' : 'Show less information' }}"
      >
        @if (menuCollapsed()) {
          <mat-icon>chevron_right</mat-icon>
        } @else {
          <mat-icon>chevron_left</mat-icon>
        }
      </button>
    </div>
  </div>

  <div class="side-menu-wrapper">
    <app-vertical-navigation
      [menuItems]="menuItems"
      [role]="roleType"
      [expectedToggleState]="menuCollapsed()"
    ></app-vertical-navigation>
  </div>

  <div class="user-feedback" *ngIf="isFeedbackCanCreateUpdate">
    <button type="button" class="btn user-feedback-btn" (click)="openFeedbackWindow()" *ngIf="!menuCollapsed()">
      Feedback
    </button>
    <mat-icon
      class="collapsed-user-feedback-btn"
      (click)="openFeedbackWindow()"
      *ngIf="menuCollapsed()"
      matTooltipPosition="right"
      matTooltip="{{ !menuCollapsed() ? '' : 'Feedback' }}"
      >feedback</mat-icon
    >
    <span class="user-feedback-placeholder" *ngIf="menuCollapsed()"></span>
  </div>

  <div [ngClass]="menuCollapsed() ? 'collapsed-profile-info' : 'profile-info'" (click)="openProfileEdit()">
    <div class="profile-info-pic">
      <img
        *ngIf="profileImageURL; else defaultAvatar"
        src="{{ profileImageURL }}"
        alt=""
        style="height: inherit; width: inherit; border-radius: 8px"
        onerror='this.onerror = null; this.src="../../assets/images/person-circle.svg"'
        matTooltipPosition="right"
        matTooltip="{{ !menuCollapsed() ? '' : 'User Profile' }}"
      />
      <ng-template #defaultAvatar>
        <img
          class="default-user-avatar"
          src="../../assets/images/person-circle.svg"
          alt=""
          style="height: inherit; width: inherit; border-radius: 8px"
        />
      </ng-template>
    </div>
    <div class="profile-info-wrap" *ngIf="!menuCollapsed()">
      <h4>{{ currentUserDetails?.fullName }}</h4>
      <span>{{ currentUserDetails?.roleName }}</span>
    </div>
  </div>

  <div class="version-label">
    <p *ngIf="!menuCollapsed()">v{{ currentApplicationVersion }}</p>
  </div>
</div>

<div class="loader-animation" *ngIf="isLoading">
  <svg-icon name="loader-animation" style="color: #8f9bba" class="input-icon"></svg-icon>
</div>
