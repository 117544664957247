import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScreenCaptureService {

  async startCapture(displayMediaOptions: MediaStreamConstraints = {
    video: {
      displaySurface: 'browser',   
    },
    audio: false,
    preferCurrentTab: true
  }): Promise<MediaStream | null> {
    let captureStream: MediaStream | null = null;

    try {
      captureStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
    } catch (err) {
      console.error(`Error: ${err}`);
    }

    return captureStream;
  }

  stopCapture(mediaStream: MediaStream) {
    const tracks = mediaStream.getTracks();
    tracks.forEach(track => track.stop());
  }
}

