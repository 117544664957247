import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { SuccessResponse } from 'src/app/shared/interfaces/Admin/SuccessResponse';
import { FeedbackApiService } from 'src/app/shared/services/feedback.service';
import { Feature, FeatureListByRoleTypeId } from '../../../shared/interfaces/Admin/Role/FeatureListByRoleTypeId';
import { User } from '../../../shared/interfaces/Auth/UserDetailsByUserIdResponse';
import { AddNewFeedbackRequest } from '../../../shared/interfaces/Feedback/AddNewFeedbackRequest';
import { AuthService } from '../../../shared/services/auth.service';
import { LoginService } from '../../../shared/services/login.service';
import { RoleService } from '../../../shared/services/role.service';
import { SubmitDialogComponent } from '../submit-dialog/submit-dialog.component';
import { FeedbackWindowService } from './feedback-window.service';

@Component({
  selector: 'app-feedback-window',
  templateUrl: './feedback-window.component.html',
  styleUrls: ['./feedback-window.component.scss'],
  providers: [RoleService],
})
export class FeedbackWindowComponent implements OnInit {
  @ViewChild('form') feedbackForm: NgForm;
  currentUserDetails: User;
  isLoading: boolean = false;
  featureList: Feature[];

  feedbackService = inject(FeedbackApiService);
  feedbackWindowService = inject(FeedbackWindowService);
  toastrService = inject(ToastrService);

  constructor(
    private _roleService: RoleService,
    private _loginService: LoginService,
    private _authService: AuthService,
    private submitDialog: MatDialog,
  ) {
    const userInfo = this._authService.getUserInfo();
    if (userInfo) {
      this.getUserDetails(userInfo.userId);
    }
  }

  async recordScreen() {
    await this.feedbackWindowService.recordScreen();
  }

  stopCapture() {
    this.feedbackWindowService.stopCapture();
  }

  get videoUrl(): string | null {
    return this.feedbackWindowService.getVideoUrl();
  }

  getUserDetails(userId: number) {
    this._loginService.getUserByID(userId).subscribe((result) => {
      this.currentUserDetails = result.result;
      this._loginService.setPracticeAreaData(result.result.practiceArea);
      this.showFeature();
    });
  }

  ngOnInit() {
    this.showFeature();
  }

  showFeature() {
    this._roleService
      .getFeatureListById(this.currentUserDetails.roleTypeId)
      .pipe(take(1))
      .subscribe((result: FeatureListByRoleTypeId) => {
        this.featureList = result.result.filter((feature) => feature.isDisplay) || [];
      });
  }

  async onSubmit(newFeedback: AddNewFeedbackRequest) {
    this.isLoading = true;
    newFeedback.userId = this.currentUserDetails.id!;

    try {
      newFeedback.video = this.feedbackWindowService.videoBlob;

      this.feedbackService
        .createFeedback(newFeedback)
        .pipe(catchError((error) => this.processError(error, 'submitting')))
        .subscribe((result) => {
          if (result.result) {
            this.openSubmitDialog();
            this.feedbackForm.resetForm();
            this.feedbackWindowService.clearVideo();
          }
          this.isLoading = false;
        });
    } catch (error) {
      console.error('Error processing video:', error);
      this.toastrService.error('Error processing video. Please try again.');
      this.isLoading = false;
    }
  }

  private processError(error: HttpErrorResponse, action: string) {
    this.toastrService.error(`Error ${action} Feedback: ${error.message}`);
    return of({ result: false, status: false } as SuccessResponse);
  }

  openSubmitDialog() {
    this.isLoading = false;
    this.submitDialog.open(SubmitDialogComponent, {
      width: '350px',
    });
  }

  closeFeedback() {
    this.feedbackWindowService.closeFeedbackWindow();
    this.feedbackWindowService.clearVideo();
  }

  get isCollapsed(): boolean {
    return this.feedbackWindowService.isFeedbackWindowCollapsed();
  }

  get isVideoLoading(): boolean {
    return this.feedbackWindowService.isVideoLoading();
  }

  clearVideo() {
    this.feedbackWindowService.clearVideo();
  }
}
