import { Component, OnDestroy } from '@angular/core';
import { FeedbackWindowService } from '../feedback-window/feedback-window.service';

@Component({
  selector: 'app-submit-dialog',
  templateUrl: './submit-dialog.component.html',
  styleUrls: ['./submit-dialog.component.scss'],
  providers: [],
})
export class SubmitDialogComponent implements OnDestroy {
  constructor(private feedbackService: FeedbackWindowService) {}

  closeDialog() {
    this.feedbackService.closeFeedbackWindow();
  }

  ngOnDestroy(): void {
    this.feedbackService.closeFeedbackWindow();
  }
}
